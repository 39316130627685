@tailwind base;
@tailwind components;
@tailwind utilities;

/* * {
    outline: 1px dotted red;
} */

#bottomNav {
    /* margin-bottom: 50px; */
}

:root {
    --light-bg-color: #dfe2dd;
    --dark-bg-color: #262627;

    --light-high-contrast-bg-color: white;
    --dark-high-contrast-bg-color: #1e1e1e;

    --light-text-color: #2c2f7e;
    --dark-text-color: #dfe2dd;

    --light-border-color: #a0a3bc;
    --dark-border-color: #444444;

    --light-foucs-bg-color: #cdd0d3;
    --focus-dark-bg-color: #393938;

    --light-high-focus-bg-color: #cfd3d2;
    --dark-high-focus-bg-color: #000;
}

/* Theme variables */
html.light {
    --bg-color: var(--light-bg-color);
    --high-contrast-bg-color: var(--light-high-contrast-bg-color);
    --text-color: var(--light-text-color);
    --border-color: var(--light-border-color);
    --focus-bg-color: var(--light-foucs-bg-color);
    --inverted-text-color: var(--light-bg-color);
    --high-focus-bg-color: var(--light-high-focus-bg-color);
}

html.dark {
    --bg-color: var(--dark-bg-color);
    --high-contrast-bg-color: var(--dark-high-contrast-bg-color);
    --text-color: var(--dark-text-color);
    --border-color: var(--dark-border-color);
    --focus-bg-color: var(--focus-dark-bg-color);
    --inverted-text-color: var(--dark-bg-color);
    --high-focus-bg-color: var(--dark-high-focus-bg-color);
}

/* Base styles */
html {
    background-color: var(--bg-color);
    color: var(--text-color);
}

.border-color {
    border-color: var(--border-color);
}
.border-text-color {
    border-color: var(--text-color);
}

.text-color {
    color: var(--text-color);
}

.text-bg-color {
    color: var(--bg-color);
}

.inverted-text-color {
    color: var(--inverted-text-color);
}

.bg-color {
    background-color: var(--bg-color);
}

.bg-text-color {
    background-color: var(--text-color);
}

.focus-bg-color {
    background-color: var(--focus-bg-color);
}

.high-focus-bg-color {
    background-color: var(--high-focus-bg-color);
}

.hover-emphasis:active,
.hover-emphasis:focus {
    background-color: var(--focus-bg-color);
}

@media (hover: hover) {
    .hover-emphasis:hover {
        background-color: var(--focus-bg-color);
    }
}

.fill-text-color {
    fill: var(--text-color);
}

.stroke-text-color {
    stroke: var(--text-color);
}

::selection {
    background-color: var(--text-color);
    color: var(--inverted-text-color);
}

@media (max-width: 400px) {
    .hide-on-400 {
        display: none;
    }
}

html.lightbox-opened {
    overflow: hidden !important;
}

.dragging {
    background-color: var(--focus-bg-color);
}

/* *:hover{
  outline:1px dotted gray;
} */

.g {
    outline: 5px solid rgba(0, 255, 0, 0.3);
}

.r {
    outline: 5px solid rgba(255, 0, 0, 0.3);
}

.b {
    outline: 5px solid rgba(0, 0, 255, 0.3);
}

.y {
    outline: 5px solid rgba(255, 255, 0, 0.3);
}

.p {
    outline: 5px solid rgba(255, 0, 255, 0.3);
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes blink {
    0% {
        border-left-color: transparent;
    }

    50% {
        border-left-color: #767676;
    }

    100% {
        border-left-color: transparent;
    }
}

.blink-border {
    border-left: 1px solid;
    animation: blink 1s infinite;
}

.dark body,
.light body {
    transition: background-color 1s ease;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.visible {
    opacity: 1;
}

.hidden {
    opacity: 0;
}

.flipContainer {
    perspective: 1000px;
}

.flipIn,
.flipOut {
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transition: transform 0.6s;
}

.flipIn {
    transform: rotateX(0deg);
}

.flipOut {
    transform: rotateX(180deg);
}

/* body {
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease, outline-color 0.5s ease;
  animation: fadeIn 0.3s ease forwards;
} */

.dark lr-file-uploader-regular {
    --darkmode: 1;
    /* Enable dark mode */
}

.light lr-file-uploader-regular {
    --darkmode: 0;
    /* Enable dark mode */
}

body {
    overflow-y: visible !important;
    overflow-x: hidden !important;
}

/* 스크롤바 전체의 스타일 */
::-webkit-scrollbar {
    width: 8px;
    /* 스크롤바의 너비 */
    border-radius: 4px;
}

/* 스크롤바 트랙 (바탕)의 스타일 */
.dark ::-webkit-scrollbar-track {
    background: black;
    /* 트랙의 배경 색상 */
}

/* 스크롤바 핸들 (움직이는 부분)의 스타일 */
.dark ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.112);
    /* 핸들의 배경 색상 */
}

/* 스크롤바 핸들을 호버할 때의 스타일 */
.dark ::-webkit-scrollbar-thumb:hover {
    background: #555;
    /* 호버 시 핸들의 배경 색상 */
}

::-webkit-scrollbar-track {
    background: #e5e5e5;
}

::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.332);
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

body.disableScroll {
    overflow-y: hidden !important;
}

html,
body {
    overscroll-behavior: contain;
}

.MuiTouchRipple-root::after {
    animation-duration: 0.3s !important;
}

.MuiTouchRipple-child {
    animation: fadeOut 3s;
}

/* todo redactor가 업그레이드 되면 없애야 함 */
/* .rx-tooltip{
  display: none;
} */

.disable-selection {
    -ms-user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

/* ios webkit에서 툴바를 viewport에 포함시키지 않는 문제를 해결하기 위한 클래스로 useCalculateViewportHeight와 셋트 */
.fit-height {
    height: calc(var(--vh, 1vh) * 99.99);
}

.dark .otu_loading {
    filter: invert(100%);
}

.inserted {
    margin: 30px 0 !important;
}

#file_uploader {
    position: fixed;
    top: 0;
}

.light .stroke-color {
    stroke: black !important;
}

.dark .stroke-color {
    stroke: white !important;
}

.dark .rx-panel-command .stroke-color {
    stroke: black !important;
}

#lightboxContainer section.imageModal {
    top: calc(env(safe-area-inset-top) + 3%);
}

.intercom-launcher {
    bottom: 90px !important;
    right: 10px !important;
    transform: scale(0.7);
}

.map-iframe {
    margin-top: 1.2rem !important;
    border-radius: 0.5rem !important;
    width: 100% !important;
}

input, textarea {
    caret-color: var(--text-color);
}


#file_uploader > div > uc-file-uploader-regular > uc-modal dialog::backdrop, body > uc-file-uploader-regular > uc-modal > dialog::backdrop {
    pointer-events: none !important;
}

/* 드래그 앤 드롭 관련 스타일 */
body.drag-over {
  position: relative;
}

body.drag-over::after {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(var(--text-color-rgb, 0, 0, 0), 0.6);
  border: 3px dashed var(--text-color, #000);
  pointer-events: none;
  z-index: 9999;
  animation: pulse 1.5s infinite alternate;
}

@keyframes pulse {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 0.7;
  }
}

