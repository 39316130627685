.root {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: black;
    position: absolute;
    left: 0;
    top: 0;
}
.root > div {
    padding: 2rem;
    color: white;
    /* max-width: 20rem; */
}
